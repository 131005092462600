
import Vue from 'vue'
import { IProduct } from '~/types/DTO/products'
import { EventsViewedCategory } from '~/enums/eventsViewedCategory'

export default Vue.extend({
  name: 'RPHNearestProductsBlock',
  components: {
    RPHNearestProductsGrid: () => import('rph/organisms/RPHNearestProductsGrid/index.vue'),
    Heading: () => import('~/components/atoms/Heading.vue'),
  },
  props: {
    isCartPage: {
      type: Boolean,
      default: false,
    },
    page: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    slice: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  computed: {
    isThankYouPage(): boolean {
      return this.$route.name === 'thank-you'
    },
    newCointainerClass(): string {
      if (this.isCartPage) return 'container-v2--cart'
      return ''
    },
    list() {
      return this.$store.getters['rph-basket/basketList']
    },
    currentTitle(): string {
      if (this.$route.path === '/delivery' || this.$route.path === '/delivery-pickup') {
        return this.list.length ? 'С данными товарами покупают' : 'Смотрите также'
      } else if (this.$route.name === 'disease-slug') {
        return 'Подходящие лекарства'
      } else if (this.page === 'personal-rec') {
        return 'Персональные рекомендации'
      }
      return 'Популярные товары'
    },
    products(): IProduct[] {
      if (this.$route.path === '/cart' || this.$route.name === 'disease-slug' || this.isCartPage) {
        return this.$store.getters['rph-products/nearestProducts']
      } else if (this.page === 'personal-rec') {
        return this.$store.getters['rph-products/personalRecProducts']
      }
      return this.$store.getters['rph-products/popularProducts']
    },

    itemListData() {
      switch (this.page) {
        case 'disease':
          return {
            item_list_id: EventsViewedCategory.Disease.id,
            item_list_name: EventsViewedCategory.Disease.name,
          }
        case 'cart':
          return {
            item_list_id: EventsViewedCategory.Basket.id,
            item_list_name: EventsViewedCategory.Basket.name,
          }

        case 'personal-rec':
          return {
            item_list_id: EventsViewedCategory.Personal.id,
            item_list_name: EventsViewedCategory.Personal.name,
          }

        default:
          return {
            item_list_id: EventsViewedCategory.Popular.id,
            item_list_name: EventsViewedCategory.Popular.name,
          }
      }
    },
  },

  mounted() {
    this.$services.productsEvents.productViewPushEvent({
      products: this.products,
      itemListData: this.itemListData,
    })
  },
  methods: {},
})
