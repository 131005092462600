
import Vue from 'vue'

export default Vue.extend({
  name: 'RPHSearchBlock',
  components: {
    RPHSearchBar: () => import('rph/organisms/RPHSearchBar/index.vue'),
    RPHSmallBannerGrid: () => import('rph/organisms/RPHSmallBannerGrid/index.vue'),
    RPHLargeBannerGrid: () => import('rph/organisms/RPHLargeBannerGrid/index.vue'),
    Heading: () => import('~/components/atoms/Heading.vue'),
  },
  data() {
    return {
      advantages: [
        {
          content: 'Широкий ассортимент',
          image: require('~/assets/images/emojis/emergency.png'),
        },
        {
          content: 'Доставка от 30 минут',
          image: require('~/assets/images/emojis/truck.png'),
        },
        {
          content: 'Редкие препараты',
          image: require('~/assets/images/emojis/pill.png'),
        },
        {
          content: 'Доступные цены',
          image: require('~/assets/images/emojis/badge.png'),
        },
      ],
    }
  },
  computed: {
    isCollapsed() {
      return this.$store.getters['rph-header/isCollapsed']
    },

    seo() {
      return this.$store.getters['rph-main/mainPageSeo']
    },
  },
})
